import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Grid,
  Pagination,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  Collapse,
  Typography,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import { Button, Input } from "../../Component/Commons";
import { VscSettings, RiSearchLine, BsFiletypeCsv } from "../../Helper/Icons";
import LprCard from "./LprCard";
import { LprDataHandler, exportCSV } from "../../service/Lpr.service";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import moment from "moment";
import {
  getAllOrgFilter,
  getLocationFilter,
  getAllCameraFilter,
} from "../../service/Filterdata.service";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import ViewLpr from "./ViewLpr";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getEventList } from "../../service/Event.service";
import LprFilter from "./LprFilter";

const LPR = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [lprData, setlprData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [gridStyle, setGridStyle] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [camEventId, setCamEventId] = useState("");
  const [alrtMsg, setAlrtMsg] = useState("");
  const [filterOrg, setfilterOrg] = useState([]);
  const [filterEvent, setfilterEvent] = useState([]);
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [camFilterList, setCamFilterList] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lprDetailsPopup, setLprDetailsPopup] = useState(false);
  const [selectedLpr, setSelectedLpr] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState("");

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  const handleClickProfile = (event) => {
    setExportFile(event.currentTarget);
  };

  console.log("selectedLpr", selectedLpr);

  const handleCloseProfile = () => {
    setExportFile(null);
  };

  const lprDataApiHandler = async (
    search,
    page,
    selectedLocation,
    selectedOrg,
    camEventId,
    alrtMsg,
    startDate,
    endDate,
    selectedEvent
  ) => {
    const { count, data, message, success, totalPage } = await LprDataHandler(
      search,
      page,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
    if (success) {
      setlprData(data);
      setDataCount(count);
    } else {
      setlprData([]);
      setDataCount(0);
    }
  };

  const handleOrgFiltersDatas = async () => {
    try {
      // setLoading(true);
      handleLocationFiltersDatas();
      const { count, data, message, success, totalPage } =
        await getAllOrgFilter();
      if (success) {
        setfilterOrg(data);
      } else {
        setfilterOrg(data);
      }
    } catch (error) {
      setfilterOrg([]);
    } finally {
      // setLoading(false);
    }
  };
  console.log("searchText", searchText);
  useEffect(() => {
    getEventListData();
  }, []);

  const getEventListData = async () => {
    const response = await getEventList();
    if (response && response.success) {
      let data = response.data.map((item) => ({
        name: item.camera_event,
        id: item.id,
      }));
      setfilterEvent(data);
    } else {
      setfilterEvent([]);
    }
  };

  const handleLocationFiltersDatas = async () => {
    try {
      handleCameraFiltersDatas();
      const { count, data, message, success, totalPage } =
        await getLocationFilter();
      if (success) {
        setLocationFilterList(data);
      } else {
        setLocationFilterList(data);
      }
    } catch (error) {
      setLocationFilterList([]);
    }
  };

  const handleCameraFiltersDatas = async () => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } =
        await getAllCameraFilter();
      if (success) {
        setCamFilterList(data);
      } else {
        setCamFilterList(data);
      }
    } catch (error) {
      setCamFilterList([]);
    } finally {
      // setLoading(false);
    }
  };

  const handleChangePage = (value, newPage) => {
    setCurrentPage(newPage);
    lprDataApiHandler(
      searchText,
      newPage,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };

  const searchHandler = (event) => {
    setSearchText(event.target.value);
    lprDataApiHandler(
      event.target.value,
      1,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };

  const closeFlagHandler = () => {
    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" });
    lprDataApiHandler(
      searchText,
      currentPage,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    lprDataApiHandler(
      searchText,
      currentPage,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
    handleOrgFiltersDatas();
  }, []);

  const handleSubmitFilter = () => {
    setCurrentPage(1);
    lprDataApiHandler(
      searchText,
      1,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };

  const clearFilter = () => {
    setSelectedLocation("");
    setSelectedOrg("");
    setCamEventId("");
    setAlrtMsg("");
    setStartDate("");
    setSelectedEvent("");
    setEndDate("");
    setShowFilter(!showFilter);
    setSearchText("");
    lprDataApiHandler("", 1, "", "", "", "", "", "", "");
  };

  const handleDownloadFile = async (e) => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } = await exportCSV(
        searchText,
        currentPage,
        selectedLocation,
        selectedOrg,
        camEventId,
        alrtMsg,
        startDate,
        endDate
      );
      if (success) {
        e.preventDefault();
        window.location.href = data;
        setIsModalOpen({
          open: true,
          currentComponent: "exportSuccess",
        });
      } else {
      }
    } catch (error) {
    } finally {
      // setLoading(false);
    }
  };

  const closePopupLpr = () => {
    setLprDetailsPopup(false);
  };

  const handleModelOpen = (id) => {
    if (id) {
      setSelectedLpr(id);
      setLprDetailsPopup(true);
    }
  };
  const title = "LPR -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("SearchforAlert")}
            value={searchText}
            onChange={(event) => searchHandler(event)}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>

        <Menu
          anchorEl={exportFile}
          open={Boolean(exportFile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            // onClick={() =>
            //   setIsModalOpen({
            //     open: true,
            //     currentComponent: "exportSuccess",
            //   })
            // }
            onClick={(e) => handleDownloadFile(e)}
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeCsv style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>{t("csv")}</div>
            </Stack>
          </MenuItem>
        </Menu>

        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button buttonName={t("Export")} onClick={handleClickProfile} />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse Collapse in={showFilter}>
        {/* <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Location")}:
            </Typography>
            <Select
              name={"loc"}
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectLocation.")}</MenuItem>
              {
                locationFilterList?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.loc_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Camera")}:
            </Typography>
            <Select
              name={"loc"}
              value={camEventId}
              onChange={(e) => setCamEventId(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectCamera.")}</MenuItem>
              {
                camFilterList?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.camera_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("OrganizationName")}
            </Typography>
            <Select
              name={"org"}
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectOrganazation.")}</MenuItem>
              {
                filterOrg?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.org_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("StartDate")}:
            </Typography>
            <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("EndDate")}:
            </Typography>
            <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
            />
          </Grid>
          <Box sx={{ mx: 1 }}>
            <Button buttonName={t("Filter")} onClick={() => handleSubmitFilter()} />
          </Box>
          <Box sx={{ mx: 1 }}>
            <Button buttonName={t("Clear")} onClick={() => clearFilter()} />
          </Box>
        </Stack> */}
        <LprFilter
          t={t}
          locationFilterList={locationFilterList}
          camFilterList={camFilterList}
          filterOrg={filterOrg}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          camEventId={camEventId}
          setCamEventId={setCamEventId}
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleSubmitFilter={handleSubmitFilter}
          clearFilter={clearFilter}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          filterEvent={filterEvent}
        />
      </Collapse>
      {gridStyle ? (
        <Grid container spacing={2}>
          {lprData && lprData.length > 0 ? (
            lprData.map((res, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <LprCard resData={res} />
                </Grid>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  color: "silver",
                  textAlign: "center",
                  paddingTop: "90px",
                  borderBottom: "none",
                  fontSize: "30px",
                }}
                colSpan="6"
              >
                {t("Norecordstodisplay")}
              </TableCell>
            </TableRow>
          )}
        </Grid>
      ) : (
        <>
          <TableContainer component={Paper} className={classes.cardContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableCell>{t("Image")}</StyledTableCell>
                <StyledTableCell>{t("AlertMessage")}</StyledTableCell>
                <StyledTableCell>{t("LICENSE_PLATE")}</StyledTableCell>
                <StyledTableCell>{t("LPR_Image")}</StyledTableCell>
                <StyledTableCell>{t("LocationName")}</StyledTableCell>
                <StyledTableCell>{t("EventType")}</StyledTableCell>
                <StyledTableCell>{t("OrganizationName")}</StyledTableCell>
                <StyledTableCell>{t("Date&Time")}</StyledTableCell>
              </TableHead>
              <TableBody>
                {lprData && lprData.length > 0 ? (
                  lprData.map((data, i) => {
                    return (
                      <StyledTableRow
                        key={i}
                        onClick={() => handleModelOpen(data.id)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <img
                              src={data?.alert_obj?.frame}
                              alt="alertName"
                              width={40}
                              height={40}
                              style={{ objectFit: "cover" }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {data?.alert_obj?.alert_message
                            ? data?.alert_obj?.alert_message
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {data?.lpr_number ? data?.lpr_number : "-"}
                        </TableCell>
                        <TableCell>
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <img
                              src={data?.lnp_frame}
                              alt="alertName"
                              width={40}
                              height={40}
                              style={{ objectFit: "cover" }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {data?.alert_obj?.location_name
                            ? data?.alert_obj?.location_name
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {data?.alert_obj?.camera_events_name
                            ? data?.alert_obj?.camera_events_name
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {data?.alert_obj?.organization_name
                            ? data?.alert_obj?.organization_name
                            : "-"}
                        </TableCell>

                        <TableCell>
                          {data?.created_at
                            ? moment(data?.created_at).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            : "-"}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="6"
                    >
                      {t("Norecordstodisplay")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {`${t("Showing")} ${lprData ? lprData.length : 0}
          ${t("of")} ${dataCount ?? 0} ${t("Results")}`}
        </div>
        <Pagination
          count={Math.ceil(dataCount / pageLimit)}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      <Dialog
        onClose={closePopupLpr}
        aria-labelledby="Common_modal"
        open={lprDetailsPopup}
      >
        <ViewLpr selectedLpr={selectedLpr} handleClose={closePopupLpr} />
      </Dialog>
    </HelmetProvider>
  );
};

export default LPR;
