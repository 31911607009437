import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Grid,
  Typography,
  Collapse,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  GrFormAdd,
  VscSettings,
  RiSearchLine,
  GoDotFill,
} from "../../Helper/Icons";
import { getUsers } from "../../service/User.service";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { StyledChip, getStatus } from "../../Helper/Helper";
import { useTranslation } from "react-i18next";
import { getGroups } from "../../service/role.service";
import { Helmet, HelmetProvider } from "react-helmet-async";
const UserManagement = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [groupData, setGroupData] = React.useState([]);


  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  const closeFlagHandler = () => {
    getUserListHandler(searchText, currentPage);
  };

  useEffect(() => {
    getUserListHandler(searchText, currentPage);
  }, []);

  const handleChangePage = (value, newPage) => {
    setCurrentPage(newPage);
    getUserListHandler(searchText, newPage);
  };

  const deleteHandler = (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "delete",
      isEdit: false,
      data: {
        head: `${t("Removeuser")}`,
        message: `${t("DoyouwanttoRemovethisuser?")}`,
        id: id,
        name: "USER",
      },
    });
  };

  const searchHandler = (event) => {
    setSearchText(event.target.value);
    const inputText = event.target.value;
    if (inputText.length >= 3) {
      getUserListHandler(inputText, 1);
    }
    if (inputText === "") {
      getUserListHandler(inputText, 1);
    }
  };

  const getUserListHandler = async (searchText, currentPage) => {
    const { count, data, message, success, totalPage } = await getUsers(
      searchText,
      currentPage
    );
    setDataCount(count);
    setUserData(data);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const title = "User -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("SearchforUser")}
            onChange={(event) => searchHandler(event)}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          <Button
            buttonName={t("AddUser")}
            startIcon={<GrFormAdd />}
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "addUser",
                isEdit: false,
              })
            }
          />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Email")}:
            </Typography>
            <Input placeholder={t("SearchEmail")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Name")}:
            </Typography>
            <Input placeholder={t("EnterName")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Role")}:
            </Typography>
            <Input placeholder={t("EnterRole")} type="text" />
          </Grid>


          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Email")}</StyledTableCell>
              <StyledTableCell> {t("FirstName")}</StyledTableCell>
              <StyledTableCell> {t("LastName")}</StyledTableCell>
              <StyledTableCell> {t("Role")} </StyledTableCell>
              <StyledTableCell>{t("Status")}</StyledTableCell>
              <StyledTableCell>{t("Action")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData && userData.length > 0 ? (
              userData.map((res) => {
                var status = getStatus(res?.is_active);
                return (
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewUser",
                        data: {
                          totalData: res,
                        },
                      })
                    }
                  >
                    <TableCell component="th" scope="row">
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                      >
                        {res.profile_image ? (
                          <img
                            src={res.profile_image}
                            width={38}
                            height={38}
                            style={{ borderRadius: "50px" }}
                            className="user_table_img"
                          />
                        ) : (
                          <AccountCircleIcon
                            sx={{ width: "40px", height: "40px" }}
                          />
                        )}
                        {res?.email}
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res?.first_name ? `${res.first_name}` : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res?.last_name ? `${res.last_name}` : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res?.groups}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <StyledChip label={status?.title} sx={status?.styles} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(res.id);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("Norecordstodisplay")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {`${t("Showing")} ${userData ? userData.length : 0}
          ${t("of")} ${dataCount ?? 0} ${t("Results")}`}
        </div>

        <Pagination
          count={Math.ceil(dataCount / pageLimit)}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </HelmetProvider>
  );
};

export default UserManagement;
