import React from "react";
import { Grid, MenuItem, Select, Typography, Box, Stack } from "@mui/material";
import { Button, Input } from "../../Component/Commons";

const LprFilter = ({
  t,
  locationFilterList,
  camFilterList,
  filterOrg,
  selectedLocation,
  setSelectedLocation,
  camEventId,
  setCamEventId,
  selectedOrg,
  setSelectedOrg,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmitFilter,
  clearFilter,
}) => {
  return (
    <>
      <Stack
        mb={"12px"}
        sx={{ background: "#151515" }}
        flexDirection={"row "}
        gap={2}
        flexWrap={"wrap"}
        justifyContent={"flex-start"}
        p={1}
        alignItems={"center"}
      >
        <Grid className="filterinput">
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {t("Location")}:
          </Typography>
          <Select
            name={"loc"}
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" hidden disabled>
              {t("SelectLocation.")}
            </MenuItem>
            {locationFilterList?.map((data, i) => {
              return (
                <MenuItem value={data.id} key={i}>
                  {data?.loc_name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid className="filterinput">
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {t("Camera")}:
          </Typography>
          <Select
            name={"loc"}
            value={camEventId}
            onChange={(e) => setCamEventId(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" hidden disabled>
              {t("SelectCamera.")}
            </MenuItem>
            {camFilterList?.map((data, i) => {
              return (
                <MenuItem value={data.id} key={i}>
                  {data?.camera_name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid className="filterinput">
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {t("OrganizationName")}
          </Typography>
          <Select
            name={"org"}
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" hidden disabled>
              {t("SelectOrganazation.")}
            </MenuItem>
            {filterOrg?.map((data, i) => {
              return (
                <MenuItem value={data.id} key={i}>
                  {data?.org_name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid className="filterinput">
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {t("StartDate")}:
          </Typography>
          <Input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Grid>
        <Grid className="filterinput">
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {t("EndDate")}:
          </Typography>
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            min={startDate}
          />
        </Grid>
        <Box sx={{ mx: 1 }}>
          <Button
            buttonName={t("Filter")}
            onClick={() => handleSubmitFilter()}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <Button buttonName={t("Clear")} onClick={() => clearFilter()} />
        </Box>
      </Stack>
    </>
  );
};

export default LprFilter;
