import { DELETE, get, post, put } from "../web.request"


//alert_for_ppe_kit
export const getAlertForPpeKitList = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate, selectedEvent) => {
  return get(`${process.env.REACT_APP_API_URL}/alert_for_ppe_kit?search=${searchText}&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}&camera_event_id=${selectedEvent}`);
}

//id_based_ppe_alert
export const getAlertForPpeKitById = (id) => {
  return get(`${process.env.REACT_APP_API_URL}/id_based_ppe_alert/${id}`);
}