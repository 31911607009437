import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../Helper/Icons";
import { CameraView } from "../../Helper/Constant";
import moment from "moment";
import { useTranslation } from "react-i18next";
import worker from "../../assets/image/worker.jpg";

function Model_PPE_KIT({ selectedAlert, handleClose, data }) {
  const { t } = useTranslation();

  console.log("selectedAlert", data);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("Details Of PPE Kit")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon onClick={handleClose} />
      </IconButton>
      <DialogContent dividers>
        <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img
            src={
              data && data?.alerts && data?.alerts[0] && data?.alerts[0].frame
            }
            style={{ width: "350px", objectFit: "contain", height: "auto" }}
          />
          <Typography>
            {data &&
              data?.alerts &&
              data?.alerts[0] &&
              data?.alerts[0].camera_name}
          </Typography>
        </Stack>
        <Box
          width={{ sm: "400px" }}
          sx={{ border: "1px solid #333", padding: "30px", color: "#fff" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography>{t("Categories")}</Typography>
                <Typography>
                  <Grid item xs={12} container direction="row">
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={2}
                      flexWrap={"wrap"}
                    >
                      {data?.alerts?.map((item, idx) => (
                        <span
                          key={idx}
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={`${
                              item.detection_choice == "No" ? "red" : "green"
                            }`}
                          ></div>
                          {item.camera_events_name}
                          {/* Assuming each item has a 'name' property */}
                        </span>
                      ))}
                    </Box>
                  </Grid>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("CameraName")}
                </Grid>
                <Grid item xs={7}>
                  {data?.alerts[0].camera_name
                    ? data?.alerts[0]?.camera_name
                    : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("AlertMessage")}
                </Grid>
                <Grid item xs={7}>
                  {data?.alerts &&
                    data?.alerts[0] &&
                    data?.alerts[0].alert_message}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("LocationName")}
                </Grid>
                <Grid item xs={7}>
                  {data?.alerts &&
                    data?.alerts[0] &&
                    data?.alerts[0].location_name}{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("OrganizationName")}
                </Grid>
                <Grid item xs={7}>
                  {data?.alerts &&
                    data?.alerts[0] &&
                    data?.alerts[0].organization_name}{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Date&Time")}{" "}
                </Grid>
                <Grid item xs={7}>
                  {moment(
                    data &&
                      data?.alerts &&
                      data?.alerts[0] &&
                      data?.alerts[0].created_at
                  ).format("DD-MM-YYYY HH:mm:ss")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
}

export default Model_PPE_KIT;
