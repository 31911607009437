import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Collapse,
  Grid,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GrFormAdd,
  VscSettings,
  RiSearchLine,
  GoDotFill,
} from "../../Helper/Icons";
import { eventDataHandler, eventDataHandlerSelectBox } from "../../service/Dashboard.service";
import moment from "moment";
import { deleteEventById, getEventDropDown } from "../../service/Event.service";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getAllCameraFilter, getAllOrgFilter, getLocationFilter } from "../../service/Filterdata.service";

const Event = ({ dashboard }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [dataCount, setDataCount] = useState(0);
  const [eventListData, setEventListData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [eventListDataForSelectBox, setEventListDataForSelectBox] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [camFilterList, setCamFilterList] = useState([]);
  const [camEventId, setCamEventId] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [filterOrg, setfilterOrg] = useState([]);
  const [areaFilterList, setAreaFilterList] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");



  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  const eventlistApiDataHandler = async (searchText, page, location, area, camera) => {

    const { count, data, message, success, totalPage } = await eventDataHandler(searchText, page, location, area, camera);
    if (success) {
      setDataCount(count);
      setEventListData(data);
    } else {
      setEventListData([]);
    }
  };
  const eventlistApiDataHandlerForSelectBox = async (dashboard) => {

    const { count, data, message, success, totalPage } = await eventDataHandlerSelectBox(dashboard);
    if (success) {
      setDataCount(count);
      setEventListDataForSelectBox(data && data.map((item) => ({
        name: item.camera_event && item.camera_event[0], value: item.id
      })));

    } else {
      setEventListDataForSelectBox([]);
    }
  };
  useEffect(() => {
    handleOrgFiltersDatas();
  }, []);

  const handelAreaFiltersDatas = async () => {
    try {
      // setLoading(true);
      let query = "";
      const { count, data, message, success, totalPage } = await getEventDropDown(query);
      if (success) {
        setAreaFilterList(data);
      } else {
        setAreaFilterList(data);
      }
    } catch (error) {
      setAreaFilterList([]);
    }
  }


  const handleLocationFiltersDatas = async () => {
    try {
      handleCameraFiltersDatas();
      handelAreaFiltersDatas();
      const { count, data, message, success, totalPage } = await getLocationFilter();
      if (success) {
        setLocationFilterList(data);
      } else {
        setLocationFilterList(data);
      }
    } catch (error) {
      setLocationFilterList([]);
    }
  };

  const handleCameraFiltersDatas = async () => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } = await getAllCameraFilter();
      if (success) {
        setCamFilterList(data);
      } else {
        setCamFilterList(data);
      }
    } catch (error) {
      setCamFilterList([]);
    } finally {
      // setLoading(false);
    }
  };
  const handleOrgFiltersDatas = async () => {
    try {
      // setLoading(true);
      handleLocationFiltersDatas();
      const { count, data, message, success, totalPage } = await getAllOrgFilter();
      if (success) {
        setfilterOrg(data);
      } else {
        setfilterOrg(data);
      }
    } catch (error) {
      setfilterOrg([]);
    } finally {
      // setLoading(false);
    }
  };
  const handleChangePage = (value, newPage) => {
    setCurrentPage(newPage);
    eventlistApiDataHandler(searchText, newPage, selectedLocation, selectedArea, camEventId);
  };

  useEffect(() => {
    eventlistApiDataHandler(searchText, currentPage, selectedLocation, selectedArea, camEventId);
    eventlistApiDataHandlerForSelectBox(dashboard);
  }, []);

  const closeFlagHandler = () => {
    eventlistApiDataHandler(searchText, currentPage, selectedLocation, selectedArea, camEventId);
    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" });
  };

  const searchHandler = (event) => {
    setSearchText(event.target.value);
    eventlistApiDataHandler(event.target.value, 1, selectedLocation, selectedArea, camEventId);
  };

  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const title = "Event -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";


  const clearFilter = () => {
    setSelectedLocation("");
    setSelectedOrg("");
    setSelectedArea("");
    setCamEventId("");
    eventlistApiDataHandler(searchText, 1, "", "", "");
  }

  const handleSubmitFilter = () => {
    setCurrentPage(1);
    eventlistApiDataHandler(searchText, 1, selectedLocation, selectedArea, camEventId);
  }


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {
        location.pathname === '/dashboard' ? '' :
          <Stack
            direction="row"
            className="table_search_head"
            justifyContent="space-between"
            width="100%"
            sx={{
              marginBottom: { xs: "12px" },
              flexDirection: { xs: "column-reverse", sm: "row" },
              gap: "8px",
              alignItems: { xs: "end", sm: "center" },
            }}
          >
            <Box className="search_field">
              <Input
                hiddenLabel
                placeholder={t("SearchforEvent")}
                onChange={(event) => searchHandler(event)}
                iconInput={<RiSearchLine style={{ color: "#292929" }} />}
                style={{
                  width: { xs: "100%", sm: "300px" },
                  "& .MuiInputBase-root": {
                    border: "1px solid #151515 !important",
                    input: {
                      background: "#151515 !important",
                      padding: "8px 15px",
                    },
                  },
                }}
              />
            </Box>
            <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>

              <Button
                buttonName={
                  <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
                }
                style={{ minWidth: "25px", padding: "6px 13px" }}
                onClick={handleFilterClick}

              />
            </Stack>
          </Stack>
      }
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          p={1}
          alignItems={"center"}
        >

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("OrganizationName")}
            </Typography>
            <Select
              name={"org"}
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectOrganazation.")}</MenuItem>
              {
                filterOrg?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.org_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Location")}:
            </Typography>
            <Select
              name={"loc"}
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectLocation.")}</MenuItem>
              {
                locationFilterList?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.loc_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Area")}:
            </Typography>
            <Select
              name={"area"}
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectArea.")}</MenuItem>
              {
                areaFilterList?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.area_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Camera")}:
            </Typography>
            <Select
              name={"loc"}
              value={camEventId}
              onChange={(e) => setCamEventId(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>{t("SelectCamera.")}</MenuItem>
              {
                camFilterList?.map((data, i) => {
                  return (
                    <MenuItem
                      value={data.id}
                      key={i}>
                      {data?.camera_name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Grid>

          <Box sx={{ mx: 1 }}>
            <Button buttonName={t("Filter")} onClick={() => handleSubmitFilter()} />
          </Box>
          <Box sx={{ mx: 1 }}>
            <Button buttonName={t("Clear")} onClick={() => clearFilter()} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Event")}</StyledTableCell>
              <StyledTableCell>{t("Location")}</StyledTableCell>
              <StyledTableCell>{t("Area")}</StyledTableCell>
              <StyledTableCell>{t("Time&Date")}</StyledTableCell>
              <StyledTableCell>{t("Camera")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              eventListData && eventListData.length > 0 ? (
                eventListData.map((data, i) => {
                  console.log("datadsdsdsds", data.camera_event)
                  return (
                    <StyledTableRow
                      key={i}
                      onClick={() =>
                        setIsModalOpen({
                          open: true,
                          currentComponent: "viewEvent",
                          data: {
                            totalData: data,
                          }
                        })
                      }
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack
                          flexDirection={"row"}
                          alignItems={"center"}
                          gap={"5px"}
                        >
                          {data.camera_event ? data.camera_event : "-"}
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {data.location_name ? data.location_name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {data.area_name ? data.area_name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(data.created_at).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {data.camera_name ? data.camera_name : '-'}
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    {t("Norecordstodisplay")}
                  </TableCell>
                </TableRow>
              )
            }

            {/* <TableRow>
              <TableCell
                sx={{
                  color: "silver",
                  textAlign: "center",
                  paddingTop: "90px",
                  borderBottom: "none",
                  fontSize: "30px",
                }}
                colSpan="6"
              >
                No records to display
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      {
        location.pathname === '/dashboard' ? '' :
          <Stack
            sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
            flexDirection="row"
            justifyContent="space-between"
          >
            <div>
              {`${t("Showing")} ${eventListData ? eventListData.length : 0}
          ${t("of")} ${dataCount ?? 0} ${t("Results")}`}
            </div>

            <Pagination
              count={Math.ceil(dataCount / pageLimit)}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
            {/* <div>
          Showing{" "}
          1{" "}
          of 1 Results
        </div> */}
            {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
          </Stack>}
      <Modal
        modalOpen={isModalOpen}
        eventListDataForSelectBox={eventListDataForSelectBox}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </HelmetProvider>
  );
};

export default Event;
